export default {
    setCidades(state, cidades) {
        state.cidades = cidades;
    },
    setBairros(state, bairros) {
        state.bairros = bairros;
    },
    setTipos(state, tipos) {
        state.tipos = tipos;
    },
    setFinalidade(state, finalidade) {
        state.finalidade = finalidade;
    },
    setSearchResult(state, search_result) {
        state.search_result = search_result;
    },
    setSearching(state, searching) {
        state.searching = searching;
    }
}