<template>
    <div class="sm-12 md-8 lg-8 offset-lg-2 vue-titulo-resultado-busca">
        <h1>
            Imóveis para {{ finalidade }} <span v-if="cidade">em {{ cidade }}</span>
        </h1>
        <p v-if="encontrados > 1">{{ encontrados }} imóveis encontrados.</p>
        <p v-else-if="encontrados == 1">1 imóvel encontrado.</p>
        <p v-else>Nenhum imóvel encontrado.</p>
    </div>
</template>
<script>
export default {
    computed: {
        finalidade() {
            return this.$store.state.finalidade == 'aluguel' ? 'alugar' : 'comprar'
        },
        cidade() {
            return this.$store.state.search_params.cidade;
        },
        encontrados() {
            return this.$store.state.search_result.length;
        }
    }
}
</script>
<style lang="less" scoped>
    h1 {
        span {
            all: unset;
        }
    }
</style>