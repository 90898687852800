<template>
    <div class="mapa vue-mapa" :api-key="apiKey" v-if="search_result.length != 0">
        <GoogleMapLoader
            :mapConfig="settings"
            :apiKey="apiKey"
            v-if="markers.length != 0"
            @bounds_changed="onBoundsChanged"
        >
            <template slot-scope="{map, google}">
                <GoogleMapMarkerClusterer :map="map" :google="google" :markers="markers"></GoogleMapMarkerClusterer>
            </template>
        </GoogleMapLoader>
    </div>
</template>
<script>
import GoogleMapLoader from "./GoogleMapLoader.vue";
import GoogleMapMarkerClusterer from "./GoogleMapMarkerClusterer.vue";
export default {
    props: ["apiKey"],
    components: {
        GoogleMapLoader,
        GoogleMapMarkerClusterer
    },
    computed: {
        settings() {
            return {
                center: {
                    lat: -30,
                    lng: -51
                },
                zoom: 18
            };
        },
        search_result() {
            return this.$store.state.search_result;
        },
        markers() {
            return this.search_result.map(row => {
                return {
                    id: row.codigo,
                    title: row.codigo,
                    position: {
                        lat: row.lat,
                        lng: row.lng
                    }
                };
            });
        }
    },
    methods: {
        onBoundsChanged({ map, google }) {
            let bounds = map.getBounds();
            this.$store.state.bounds = bounds;
        }
    }
};
</script>
<style lang="less">
.custom-cluster-icon {
    img {
        display: none;
    }
    div {
        position: absolute;
        //background: #BD090C;
        background-image: url(../img/Group.svg);
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            line-height: 1;
            margin: 0;
            color: #333;
        }
    }
}
</style>