<template>
  <select v-model="Selecionado">
      <option value="">Cidade</option>
      <option :value="cidade.slug" v-for="cidade in cidades" :key="cidade.slug">{{ cidade.titulo }}</option>
  </select>
</template>
<script>
export default {
  props: ["value", "finalidade"],
  computed: {
    cidades() {
        let cidades = this.$store.state.cidades;      
        if (this.finalidade) {
          cidades = cidades.filter(cidade => cidade.finalidades.includes(this.finalidade));
        }
        return cidades;
    },
    Selecionado: {
      get() {
        return this.selecionado;
      },
      set(value) {
        this.selecionado = value;
        this.$emit('input', value);
      },
    },
  },
  data() {
    return {
      selecionado: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
          if (value instanceof Array) {
              let [selecionado] = value;
              this.selecionado = selecionado;
          }
        this.selecionado = value || '';
      },
    },
  },
};
</script>
<style lang="less" scoped>
    select {
        background: transparent;
    }
</style>