<template>
  <div class="multiselect vue-select-tipos">
    <span class="multiselect-selection">{{ this.selecionados.length == 0 ? 'Tipos' : this.selecionados.length+' SELECS.'}}</span>
    <ul>
      <li v-for="tipo in tipos" :key="tipo.slug">
        <label>
          <input type="checkbox" v-model="Selecionados" :value="tipo.slug" />
          <span class="text-label">{{ tipo.titulo }}</span>
        </label>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["value", "finalidade"],
  data() {
    return {
      selecionados: [],
    };
  },
  computed: {
    tipos() {
      let tipos = this.$store.state.tipos;
      if (this.finalidade) {
        tipos = tipos.filter(tipo => tipo.finalidades.includes(this.finalidade));
      }
      return tipos;
    },
    Selecionados: {
      get() {
        return this.selecionados;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selecionados = value || [];
      },
    },
  },
};
</script>