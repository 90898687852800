<template>
    <Intersect @enter="loadData">
        <li>
            <a :href="url" target="_blank" class="property-item" v-if="imovel">
                <ul class="labels" v-if="tipo">
                    <li style="background-color: #efbe11">{{ tipo.titulo }}</li>
                </ul>
                <figure
                    v-if="foto"
                    class="image is-1by1"
                    :style="`background-image: url('imobiliar/Fotos/${codigo_imovel}/${foto.codigo}')`"
                >
                    <img :src="`static/images/bulma/1by1.png`" alt="" />
                </figure>
                <figure
                    v-else
                    class="image is-1by1"
                    :style="`background-image: url('static/images/noimage.svg')`"
                >
                    <img :src="`static/images/bulma/1by1.png`" alt="" />
                </figure>
                <h2>
                    {{ valor | number_format }}
                    <small v-if="finalidade == 'aluguel'">/ mês</small>
                </h2>
                <p>
                    {{ imovel.descricao | resumo }}
                </p>
                <p v-if="finalidade == 'aluguel'">
                    {{ imovel.logradouro }} - {{ imovel.titulo_bairro }}
                </p>
                <ul class="infos">
                    <li v-if="imovel.area_total > 0">
                        <img :src="`static/images/icon-area.png`" alt="" />
                        <span>{{ imovel.area_total }}m²</span>
                    </li>
                    <li v-else-if="imovel.area_privativa > 0">
                        <img :src="`static/images/icon-area.png`" alt="" />
                        <span>{{ imovel.area_privativa }}m²</span>
                    </li>
                    <li v-if="imovel.dormitorios > 0">
                        <img :src="`static/images/icon-bed.png`" alt="" />
                        <span>{{ imovel.dormitorios }}</span>
                    </li>
                    <li v-if="false">
                        <img :src="`static/images/icon-shower.png`" alt="" />
                        <span>1</span>
                    </li>
                    <li v-if="imovel.vagas > 0">
                        <img :src="`static/images/icon-garage.png`" alt="" />
                        <span>{{ vagas }}</span>
                    </li>
                </ul>
            </a>
        </li>
    </Intersect>
</template>
<script>
import Intersect from "vue-intersect";
import api from "../plugins/api";

export default {
    props: ["codigo_imovel"],
    components: {
        Intersect,
    },
    filters: {
        number_format(value) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: "BRL" }).format(value);
        },
        resumo(value, chars = 20) {
            let keywords = value.split(/\s+/);
            let txt = '';
            let index = 0;
            while (txt.length < chars && index < keywords.length) {
                txt += ' ' + keywords[index];
                index++;
            }
            if (index < keywords.length) {
                txt += '...';
            }
            return txt;
        }
    },
    data() {
        return {
            loading: true,
            imovel: null,
            fotos: null,
            foto: null,
        };
    },
    computed: {
        finalidade() {
            return this.$store.state.finalidade;
        },
        valor() {
            if (this.finalidade == "aluguel") {
                return this.imovel.valor_aluguel;
            }
            return this.imovel.valor_venda;
        },
        tipos() {
            return this.$store.state.tipos;
        },
        tipo() {
            let tipos = this.tipos;
            let tipo = tipos.find(t => t.slug == this.imovel.slug_tipo);
            
            return tipo;
        },
        url() {
            if (this.imovel.dormitorios == 0) {
                return `${document.baseURI}imovel/${this.finalidade}/${this.codigo_imovel}/${this.imovel.slug_tipo}-no-bairro-${this.imovel.slug_bairro}`;
            }
            return `${document.baseURI}imovel/${this.finalidade}/${this.codigo_imovel}/${this.imovel.slug_tipo}-${this.imovel.dormitorios}dorm-no-bairro-${this.imovel.slug_bairro}`;
        }
    },
    methods: {
        async loadData() {
            if (this.imovel != null) {
                return;
            }
            try {
                this.loading = true;
                let { data: imovel } = await api.get(
                    `imovel/${this.codigo_imovel}`
                );
                let { data: fotos } = await api.get(
                    `imovel/${this.codigo_imovel}/fotos`
                );
                this.imovel = imovel;
                fotos.sort((a,b) => {
                    return a.ordenamento < b.ordenamento ? -1 : 1;
                })
                this.fotos = fotos;
                if (fotos.length) {
                    let [foto] = fotos;
                    this.foto = foto;
                }
                
            } catch (err) {
                console.error(err);
            }
            this.loading = false;
        },
    },
};
</script>