<template>
    <form
        action="lista"
        class="advanced-filters vue-advanced-filters"
        
        @submit.prevent.stop="filtrar"
    >
        <div class="row">
            <div class="sm-12 md-4">
                <h2>Busca avançada</h2>
                <p>Utilize nossos filtros e encontre o imóvel ideial.</p>
                
            </div>
            <div class="sm-12 md-4">
                <ul class="type-list">
                    <li>
                        <label  @click.stop.prevent="modalidade = modalidade == 'comercial' ? '' : 'comercial' ">
                            <input
                                type="radio"
                                value="comercial"
                                v-model="modalidade"
                            />
                            comercial
                        </label>
                    </li>
                    <li>
                        <label  @click.stop.prevent="modalidade = modalidade == 'residencial' ? '' : 'residencial' ">
                            <input
                                type="radio"
                                value="residencial"
                                v-model="modalidade"
                            />
                            residencial
                        </label>
                    </li>
                </ul>
            </div>
            <div class="sm-12 md-4">
                <h3>Valor:</h3>
                <div class="row">
                    <div class="sm-12 md-6">
                        <input type="text" placeholder="R$ De" v-model="valor_de" />
                        <input-integer type="hidden" v-model="valor_de" />
                    </div>
                    <div class="sm-12 md-6">
                        <input type="text" placeholder="R$ Até" v-model="valor_ate" />
                        <input-integer type="hidden" v-model="valor_ate" />
                    </div>
                </div>
            </div>

            <div class="sm-12 md-3">
                <h3>Área:</h3>
                <div class="row">
                    <div class="sm-12 md-6">
                        <input type="text" placeholder="M² De" v-model="area_de" />
                        <input-integer type="hidden" v-model="area_de" />
                    </div>
                    <div class="sm-12 md-6">
                        <input type="text" placeholder="M² Até" v-model="area_ate" />
                        <input-integer type="hidden" v-model="area_ate" />
                    </div>
                </div>
            </div>
            <div class="sm-12 md-3">
                <h3>Cidade:</h3>
                <SelectCidade
                    v-model="cidade"
                    :finalidade="finalidade"
                ></SelectCidade>
            </div>
            <div class="sm-12 md-3">
                <h3>Bairros:</h3>
                <SelectBairros
                    v-model="bairros"
                    :cidade_slug="cidade"
                    :finalidade="finalidade"
                ></SelectBairros>
            </div>
            <div class="sm-12 md-3">
                <h3>Tipos:</h3>
                <SelectTipos
                    v-model="tipos"
                    :finalidade="finalidade"
                ></SelectTipos>
            </div>

            <div class="sm-12 md-4">
                <h3>Dormitórios:</h3>
                <ul class="marker-boxes">
                    <li v-for="i in 5" :key="i">
                        <label
                            :class="{ active: i == dormitorios }"
                            @click.prevent.stop="
                                dormitorios = i == dormitorios ? 0 : i
                            "
                        >
                            <input
                                type="radio"
                                v-model="dormitorios"
                                :value="i"
                            />
                            {{ i }}
                            {{ i < 5 ? "" : "ou +" }}
                        </label>
                    </li>
                </ul>
            </div>
            <div class="sm-12 md-4">
                <h3>Vagas:</h3>
                <ul class="marker-boxes">
                    <li v-for="i in 5" :key="i">
                        <label
                            :class="{ active: i == vagas }"
                            @click.prevent.stop="vagas = i == vagas ? 0 : i"
                        >
                            <input type="radio" v-model="vagas" :value="i" />
                            {{ i }}
                            {{ i < 5 ? "" : "ou +" }}
                        </label>
                    </li>
                </ul>
            </div>
            <div class="sm-12 md-4">
                <button type="submit" class="default-button">filtrar</button>
            </div>
        </div>
    </form>
</template>
<script>
import SelectCidade from "./select-cidades.vue";
import SelectBairros from "./select-bairros.vue";
import SelectTipos from "./select-tipos.vue";
import InputInteger from './input-integer.vue';


import qs from "qs";

export default {
    components: {
        SelectCidade,
        SelectBairros,
        SelectTipos,
        InputInteger
    },
    computed: {
        finalidade() {
            return this.$store.state.finalidade;
        },
    },
    data() {
        return {
            modalidade: null,
            cidade: null,
            bairros: [],
            tipos: [],
            area_de: null,
            area_ate: null,
            valor_de: null,
            valor_ate: null,
            dormitorios: 0,
            vagas: 0,
        };
    },
    methods: {
        filtrar() {
            let query = {
                modalidade: this.modalidade,
                valor_de: this.valor_de,
                valor_ate: this.valor_ate,
                area_de: this.area_de,
                area_ate: this.area_ate,
                cidade: this.cidade,
                bairros: this.bairros,
                tipos: this.tipos,
                dormitorios: this.dormitorios,
                vagas: this.vagas,
            };

            query = qs.stringify(query, { arrayFormat: "brackets" });

            let url = `${document.baseURI}${this.finalidade}?${query}`;
            location.href = url;
        }
    },
    created() {
        let query = qs.parse(location.search.replace(/^\?/igm,''));
        if (query.modalidade && query.modalidade != '') {
            this.modalidade = query.modalidade;
        }
        if (query.valor_de && query.valor_de != '') {
            this.valor_de = +query.valor_de;
        }
        if (query.valor_ate && query.valor_ate != '') {
            this.valor_ate = +query.valor_ate;
        }
        if (query.area_de && query.area_de != '') {
            this.area_de = +query.area_de;
        }
        if (query.area_ate && query.area_ate != '') {
            this.area_ate = +query.area_ate;
        }
        if (query.dormitorios && query.dormitorios != '') {
            this.dormitorios = +query.dormitorios;
        }
        if (query.vagas && query.vagas != '') {
            this.vagas = +query.vagas;
        }
        if (query.cidade && query.cidade != '') {
            this.cidade = query.cidade;
        }
        if (query.bairros && query.bairros != '') {
            this.bairros = query.bairros;
        }
        if (query.tipos && query.tipos != '') {
            this.tipos = query.tipos;
        }
        
    }
};
</script>
<style lang="less" scoped>
.marker-boxes {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}
input[type="radio"] {
    pointer-events: none;
}
</style>