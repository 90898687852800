<template>
    <ul class="sm-grid-2 md-grid-2 lg-grid-3" v-if="search_result.length != 0">
        <Imovel v-for="imovel in search_result" :key="imovel.codigo" :codigo_imovel="imovel.codigo"></Imovel>
    </ul>
    <div v-else-if="searching">
        <p>Buscando...</p>
    </div>
    <div v-else>
        <p>Nenhum imóvel encontrado</p>
    </div>
</template>
<script>
import Imovel from './Imovel.vue';
export default {
    components: {
        Imovel
    },
    computed: {
        searching() {
            return this.$store.state.searching;
        },
        search_result() {
            let rows = this.$store.state.search_result;
            let bounds = this.$store.state.bounds;
            if (bounds) {
                rows = rows.filter(({ lat, lng }) => {
                    return bounds.contains(({ lat, lng }));
                });
            }
            return rows;
        }
    }
};
</script>