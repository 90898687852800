<template>
    <div class="wrap-map vue-mapa-interna" :api-key="apiKey" :lat="lat" :lng="lng">
        <GoogleMapLoader
            :mapConfig="settings"
            :apiKey="apiKey"            
        >
            <template slot-scope="{map, google}">
                <GMarker :marker="marker" :map="map" :google="google"></GMarker>
            </template>
        </GoogleMapLoader>
    </div>
</template>
<script>
import GoogleMapLoader from "./GoogleMapLoader.vue";
import GMarker from './GoogleMapMarker';
export default {
    props: ['apiKey', 'lat', 'lng'],
    components: {
        GoogleMapLoader,
        GMarker
    },
    computed: {
        settings() {
            return {
                center: {
                    lat: this.lat,
                    lng: this.lng
                },
                zoom: 17
            }
        },
        marker() {
            return {
                position: {
                    lat: this.lat,
                    lng: this.lng
                }
            }
        }
    }
}
</script>
<style lang="less">
.custom-cluster-icon {
    img {
        display: none;
    }
    div {
        position: absolute;
        //background: #BD090C;
        background-image: url(../img/Group.svg);
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            line-height: 1;
            margin: 0;
            color: #333;
        }
    }
}
</style>