import Vuex from 'vuex';
import Vue from 'vue';

import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

Vue.use(Vuex);

export default () => {
    const store = new Vuex.Store({
        state,
        actions,
        mutations,
        getters
    });

    if (module.hot) {
        module.hot.accept(
            ["./actions", "./mutations", "./getters", "./modules"],
            () => {
                const actions = require("./actions").default;
                const mutations = require("./mutations").default;
                const getters = require("./getters").default;
                store.hotUpdate({
                    actions,
                    mutations,
                    getters
                });
            }
        );
    }

    store.dispatch('loadCidades');
    store.dispatch('loadBairros');
    store.dispatch('loadTipos');
    store.dispatch('search');
    return store;
};