import Vue from 'vue';
import createStore from './store';
import FiltroBanner from './components/FiltroBanner.vue';
import BuscaAvancada from './components/BuscaAvancada.vue';
import ResultadoBusca from './components/ResultadoBusca.vue';
import TituloResultadoBusca from './components/TituloResultadoBusca.vue';
import Mapa from './components/Mapa.vue';
import MapaInterna from './components/MapaInterna.vue';

const store = createStore();

if (document.querySelector('.vue-filtro-banner')) {
    let els = document.querySelectorAll('.vue-filtro-banner');
    els.forEach(el => {        
        new Vue({
            store,
            render: h => h(FiltroBanner)
        }).$mount(el);
    });
}

if (document.querySelector('.vue-advanced-filters')) {
    let els = document.querySelectorAll('.vue-advanced-filters');
    els.forEach(el => {
        new Vue({
            store,
            render: h => h(BuscaAvancada)
        }).$mount(el);
    });
}

if (document.querySelector('.vue-search-result')) {
    let els = document.querySelectorAll('.vue-search-result');
    els.forEach(el => {
        new Vue({
            store,
            render: h => h(ResultadoBusca)
        }).$mount(el);
    });
}

if (document.querySelector('.vue-titulo-resultado-busca')) {
    let els = document.querySelectorAll('.vue-titulo-resultado-busca');
    els.forEach(el => {
        new Vue({
            store,
            render: h => h(TituloResultadoBusca)
        }).$mount(el);
    });
}
if (document.querySelector('.vue-mapa')) {
    let els = document.querySelectorAll('.vue-mapa');
    els.forEach(el => {
        let apiKey = el.getAttribute("api-key");
        new Vue({
            store,
            render: h => h(Mapa, {
                props: {
                    apiKey
                }
            })
        }).$mount(el);
    });
}
if (document.querySelector('.vue-mapa-interna')) {
    let els = document.querySelectorAll('.vue-mapa-interna');
    els.forEach(el => {
        let apiKey = el.getAttribute("api-key");
        let lat = +el.getAttribute("lat");
        let lng = +el.getAttribute("lng");
        new Vue({
            store,
            render: h => h(MapaInterna, {
                props: {
                    apiKey,
                    lat,
                    lng
                }
            })
        }).$mount(el);
    });
}



if (module.hot) {
    module.hot.accept(() => {
        
    });
}

import '../css/style.less'

import '../js/app';
import '../js/slider';