import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import './meiomask.min.js';
import F from 'fancybox';
F($);

$.fancybox.showLoading = () => {
	$('.fancybox-loading').remove();
	$(document.body).append(`
		<div class="fancybox-loading" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5); color: white;display:flex;justify-content: center;align-items:center;z-index: 999999">
			<i class="fa fa-spin fa-3x fa-spinner"></i>
		</div>
	`);
};
$.fancybox.hideLoading = () => {
	$('.fancybox-loading').remove();
}

import 'fancybox/dist/css/jquery.fancybox.css';
import './validate.min.js';

var theWindow = $(window).width();

$(document).ready(function() {

	//mobile menu
	$('.toggle-menu').on('click', function(){
		$('.mobile-menu').toggleClass('opened');
	});

	//action

	$(document).on('click', '.banner-filter .filter-mode label', function() {
		$('.banner-filter .filter-mode label').removeClass('active');
		$(this).addClass('active');
	});

	$(document).on('click', ' label', function() {
		$(this).closest('.marker-boxes').find('label').removeClass('active');
		$(this).addClass('active');
	});

	$(document).on('click', '.advanced-open', function() {
		var y = $(window).scrollTop();
 		$('html, body').animate({ scrollTop: y + 250 }) 
		$('.advanced-filters').slideToggle();
	});


	$('.wrap-head-property .photos-button').on('click', function() {
		$('.wrap-head-property .wrap-buttons a').removeClass('active');
		$(this).addClass('active');

		$('.carousel-property').show();
		$('.wrap-map').hide();
	});

	$('.wrap-head-property .map-button').on('click', function() {
		$('.wrap-head-property .wrap-buttons a').removeClass('active');
		$(this).addClass('active');

		$('.wrap-map').show();
		$('.carousel-property').hide();
	});
	

	//carousel

	
	$('.carousel-about').slick({
		autoplay: true,
		arrows: true,
		autoplaySpeed: 6000,
		slidesToShow: 1
	});	
	
	$('.carousel-related').slick({
		autoplay: true,
		arrows: true,
		autoplaySpeed: 6000,
		slidesToShow: 4,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
				}
			}
		]
	});	
	
	$('.carousel-property').slick({
		autoplay: true,
		arrows: true,
		autoplaySpeed: 6000,
		slidesToShow: 4,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	});	
	

	$('.carousel-products-home').slick({
		autoplay: true,
		autoplaySpeed: 6000,
		slidesToShow: 3,
		dots: false,
		arrow: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	});

	$('.carousel-blog').slick({
		autoplay: true,
		autoplaySpeed: 6000,
		dots: true,
		arrows: true,
		slidesToShow: 3,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				}
			}
		]
	});	

	$('.carousel-product').slick({
		arrows: false,
		asNavFor: '.carousel-product-thumb'
	});

	$('.carousel-product-thumb').slick({
		arrows: false,
		infinite: true,
		focusOnSelect: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		asNavFor: '.carousel-product'
	});

	
	//instafeed
	if ($('#instafeed').length != 0) {
		var sc = document.createElement('script');
		sc.src = 'static/js/vendors/instafeed/instafeed.min.js';
		sc.onload = function() {
								
			var userFeed = new Instafeed({
			  	get: 'user',
			  	userId: (window.userId||[]),
			  	limit: (window.limit||[]),
			  	resolution: 'standard_resolution',
			  	accessToken: (window.accessToken||[]),
			  	template: '<li><a href="{{link}}" target="blank_" class="link"><figure class="image is-1by1" style="background-image: url({{image}});"><img src="static/images/bulma/1by1.png" /></figure></a></li>'
			});
			userFeed.run();
		}
		document.head.appendChild(sc);
	}

	//Multiselect

    $(document).on('click', ".multiselect-selection", function (e) {
        var $self = $(this).closest(".multiselect");
        var $ul = $self.find("ul");
        $("body").off("click", ":not(.multiselect,.multiselect *)");

        if ($ul.is(":hidden")) {
            $(".multiselect > ul").slideUp(100);
            $ul.slideDown(100);
            $("body").off("click", ":not(.multiselect,.multiselect *)");
            window.setTimeout(function () {
                $("body").on(
                    "click",
                    ":not(.multiselect,.multiselect *)",
                    function (e) {
                        var $el = $(e.target);
                        if ($el.closest(".multiselect").length != 0) return;
                        $(".multiselect > ul").slideUp(100);
                        $("body").off(
                            "click",
                            ":not(.multiselect,.multiselect *)"
                        );
                        if ($el.is('button') || $el.is('a')) {
                            return;
                        }
                        e.stopPropagation();
                        e.preventDefault();
                        return false;
                    }
                );
            }, 0);
        } else {
            $ul.slideUp(100);
            $("body").off("click", ":not(.multiselect,.multiselect *)");
        }
    });

    $(document).on('click', '.multiselect :checkbox', function () {
        var $that = $(this);
        var $parent = $that.closest('.multiselect');
        var $selection = $parent.find('.multiselect-selection');

        var def = $parent.data('def');
        if (!def) {
            def = $selection.text();
            $parent.data('def', def);
        }

        var qtd = $parent.find(':checked').length;

        if (qtd <= 0) {
           //$selection.text(def);
        } else {
            //$selection.text(qtd + " selecs.");
        }
    });



    //multiselect box
    $(document).on('click', '.multiselec-box-close', function() {
    	$('.multiselect-box-area').fadeOut(100);
    });

	/*
    $(document).on('click', ".multiselect-selection-box", function (e) {
        var $self = $(this).closest(".multiselect-box");
        var $ul = $self.find(".multiselect-box-area");
        $("body").off("click", ":not(.multiselect-box,.multiselect-box *)");

        if ($ul.is(":hidden")) {
            $(".multiselect-box > ul").fadeOut(100);
            $ul.fadeIn(200);
            $("body").off("click", ":not(.multiselect-box,.multiselect-box *)");
            window.setTimeout(function () {
                $("body").on(
                    "click",
                    ":not(.multiselect-box,.multiselect-box *)",
                    function (e) {
                        $el = $(e.target);
                        if ($el.closest(".multiselect-box").length != 0) return;
                        $(".multiselect-box .multiselect-box-area").fadeOut(100);
                        $("body").off(
                            "click",
                            ":not(.multiselect-box,.multiselect-box *)"
                        );
                        if ($el.is('button') || $el.is('a')) {
                            return;
                        }
                        e.stopPropagation();
                        e.preventDefault();
                        return false;
                    }
                );
            }, 0);
        } else {
            $ul.fadeOut(100);
            $("body").off("click", ":not(.multiselect-box,.multiselect-box *)");
        }
    });
	*/
    $(document).on('click', '.multiselect-box :checkbox', function () {
        var $that = $(this);
        var $parent = $that.closest('.multiselect-box');
        var $selection = $parent.find('.multiselect-selection-box');

        var def = $parent.data('def');
        if (!def) {
            def = $selection.text();
            $parent.data('def', def);
        }

        var qtd = $parent.find(':checked').length;

        if (qtd <= 0) {
           //$selection.text(def);
        } else {
            //$selection.text(qtd + " selecs.");
        }
    });

    //end multiselect


	//fancybox

	$('.fancybox').fancybox({
		helpers : {
			title : {
				type : 'inside'
			},
			overlay : {
				locked : false
			}
		},
		afterShow: function() {
			$(':text').setMask();
		}
	});

	//data

	$('[data-menu]').each(function(){
		var menu = $(this).data('menu');
		if ($('body').is('.'+menu)) {
			$(this).find('a:eq(0)').addClass('active');
		}
	});
	
});


var language = 'br';
var Modelo = {
	
	init : function(){
		
		language = $('meta[name=language]').attr('content');
		
		Modelo.ajaxForm('[name=formNewsletter],[name=formContato],[name=formOrcamento]');
		
	
		
		$('input[alt=phone]').each(function(){
			$(this).click(function () {
				$.mask.masks.phone.mask = '(99) 9999-99999';
				$(':text').setMask();
			});
			$(this).blur(function () {
				var phone, element;
				element = $(this);
				phone = element.val().replace(/\D/g, '');
				if(phone.length > 10) {
					element.setMask("(99) 99999-9999?9");
					} else {
					element.setMask("(99) 9999-9999?9");
				}
			});
		});
		
		
		$('body').on('submit','[name=formTrabalheConosco]',function(){
			var $self = $(this);
			
			if ($self.data('enviando')) return false;
			if (!$self.find('.required').validate_sgt()) return false;
			
			$self.data('enviando',true);
			$.fancybox.showLoading();
			
			var callback = function(resp) {
				if (resp.redirect_url != undefined) {
					location.href = resp.redirect_url;
					return;
				}
				
				$.fancybox.hideLoading();
				$self.data('enviando',false);
				if (resp.popup_url != undefined) {
					
					$.fancybox.open([{
						href: resp.popup_url,
						type: ajax
					}]);
					
				}
				swal(resp.msg);
				if (resp.success) {
					$self[0].reset();
					$self.find('.btn-upload').val('Selecionar');
					$self.find('[name*=curriculo]').remove();
				}
			};
			
			$.ajax({
				url: $self.attr('action')+'?t='+Date.now(),
				method: 'post',
				dataType: 'json',
				data: $self.serializeArray(),
				success: callback,
				error: function() {
					callback({success:false,msg:"Não foi possível enviar o formulário."});
				}
			});
			
			return false;
		});
		
		$('[data-large_background]').each(function(){
			var $self = $(this);
			var src = $self.data('large_background');
			console.log(src);
			(function($self,src){
				var img = new Image();
				img.onload = function() {
					$self.css('transition','background-image 0.5s');
					$self.css('background-image','url('+src+')');
				};
				img.src = src;
			}($self,src));
			
		});
		
	},
	
	ajaxForm: function(selector,cb) {
		$('body').on('submit',selector,function(){
			var $self = $(this);
			if ($self.data('enviando')) return false;
			if (!$self.find('.required').validate_sgt()) return false;
			
			$self.data('enviando',true);
			$.fancybox.showLoading();
			
			var callback = function(resp) {
				if (resp.redirect_url != undefined) {
					location.href = resp.redirect_url;
					return;
				}
				$self.data('enviando',false);
				$.fancybox.hideLoading();
				
				if (cb) return cb(resp,$self);
				alert(resp.msg);
				if (resp.success) {
					$self[0].reset();
					$self.find('[alt=phone]').val('');
					if (resp.redirect_url != undefined) {
						location.href = resp.redirect_url;
					} else {
						console.log('reload');
						location.reload(); 
					}
				}
			}
			
			$.ajax({
				url: $self.attr('action')+'?t='+Date.now(),
				type: 'post',
				dataType: 'json',
				data: $self.serializeArray(),
				success: callback,
				error: function() {
					callback({success:false,msg:"Não foi possível enviar o formulário."});
				}
			});
			return false;
		});
	},
	
	
}
$(document).ready(function(){
	$(Modelo.init);
});

/**
	* MEDIAS
*/
// > 1024 pixels
//if($(window).width() > 1024) {

//Habilita Máscara apenas para desktops
$(function(){
	$.mask.masks.phone.mask = '(99) 9999-99999';
	$(':text').setMask();

	$('[data-background]').each(function() {
		var $self = $(this);
		
		(function($el){
			var src = $self.data('background');
			var img = new Image();
			img.onload = function() {
				$self.css('transition','background-image 0.5s');
				$self.css('background-image','url('+src+')');
			};
			img.src = src;
		}($self));
	});

	$('[data-src]').each(function() {
		var $self = $(this);
		
		(function($el){
			var src = $self.data('src');
			var img = new Image();
			img.onload = function() {
				$self.attr('src',src);
			};
			img.src = src;
		}($self));
	});
});
//}

$(function(){
	var $img = $('#flyer img');
	if ($img.length==0) return;
	var flyer = new Image();
	flyer.onload = function() {
		$.fancybox({
			href:'#flyer',
			autoResize : false,
			autoCenter : false,
			autoSize : true
		});
	}
	flyer.src = $('#flyer img').attr('src');
});								

$(document).ready(function() { 
	
	
	$(".fancybox_video").fancybox({
		'width': 560,
		'height': 315,
		'type': 'iframe',
		'fitToView' : true,
		autoResize : true,
		autoCenter : true,
		autoSize : true
	});
	
	var $iframe = $('#flyer .video a');
	if ($iframe.length==0) return false;
	$iframe.trigger('click');
	
});

$(document).ready(function(){
	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires="+d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
	
	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for(var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}
	
	function checkCookie() {
		var lgpd = getCookie("lgpd");
		if (lgpd == "") {
			$(".lgpd-all").show();
		}
	}
	
	checkCookie();
	
	$('.lgpd-accept').click(function(){
		setCookie("lgpd", 1, 365);
		$(".lgpd-all").hide();
	});
	
});