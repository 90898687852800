<script>
    import MarkerClusterer from '@googlemaps/markerclustererplus';
    import icon from '../img/Single.svg';
    export default {
        
        props: {
            google: {
                type: Object,
                required: true,
            },
            map: {
                type: Object,
                required: true,
            },
            markers: {
                type: Array,
                required: true,
            },
        },

        mounted() {
            let markers = this.markers.map(marker => {                
                return new this.google.maps.Marker({
                    title: `${marker.title}`,
                    position: marker.position,
                    marker: marker,          
                    icon: `${document.baseURI}static/dist/${icon}`          
                });
            });
            const settings = {
                clusterClass: 'custom-cluster-icon',
                styles: [{
                    textColor: '#000000',
                    textSize: 10,
                    width: 45,
                    height: 45,
                    //url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png'
                }]
            };

            let bounds = new this.google.maps.LatLngBounds();
            markers.forEach(marker => {
                
                bounds.extend(marker.getPosition());
            });
            console.log({ bounds })
            this.map.fitBounds(bounds);

            new MarkerClusterer(this.map, markers, settings);
        },
        render() {}
    };
</script>