<template>
    <div class="multiselect-box" tabindex="0" @blur="onBlur">
        <span class="multiselect-selection-box" @click="open">
            {{
                this.value.length == 0
                    ? "BAIRROS"
                    : this.value.length + " SELECS."
            }}
        </span>
        
        <div class="multiselect-box-area" :class="{show: show_modal}">
            <div class="inner">
                <a
                    href="javascript:void(0);"
                    class="multiselec-box-close close-times"
                    @click="close"
                >
                    <i class="fas fa-times"></i>
                </a>

                <ul class="sm-grid-2 md-grid-4 lg-grid-4 xl-grid-5">
                    <li v-for="bairro in bairros" :key="bairro.slug">
                        <label @click.prevent.stop="e => toggle(bairro.slug)">
                            <input  type="checkbox" :value="bairro.slug" v-model="selecionados" />
                            <span class="text-label">{{ bairro.titulo }}</span>
                        </label>
                    </li>
                </ul>

                <div class="flex-centered">
                    <div
                        href="javascript:void(0);"
                        class="default-button"
                        @click.prevent.stop="concluir"
                    >
                        Concluir
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["value", "finalidade", "cidade_slug"],
    data() {
        return {
            show_modal: false,
            selecionados: [],
        };
    },
    methods: {
        concluir() {            
            this.$emit('input', this.selecionados);
            
            this.close();
        },
        open() {
            this.selecionados = this.value||[];
            if (!this.cidade_slug) {
                return;
            }
            this.show_modal = true;
            let handler = e => {
                switch (e.key) {
                    case 'Escape':
                        this.close();
                        break;
                    case 'Enter':
                        this.concluir();
                        break;
                    default:
                        //console.log(e.key);
                        break;
                }
            };
            window.addEventListener('keyup', handler);
            this.$on('destroy', () => {
                window.removeEventListener('keyup', handler);
            });
            this.$on('closed', () => {
                window.removeEventListener('keyup', handler);
            });
        },
        close() {
            this.show_modal = false;
            this.$emit('closed')
        },
        onBlur() {
            this.close();
        },
        toggle(bairro_slug) {
            if (this.selecionados.includes(bairro_slug)) {
                this.selecionados = this.selecionados.filter(i => i!=bairro_slug);
            } else {
                this.selecionados = [...this.selecionados, bairro_slug]
            }
        }
    },
    computed: {
        Selecionados: {
            get() {
                return this.selecionados;
            },
            set(value) {
                this.selecionados = value;
            },
        },
        bairros() {
            let bairros = this.$store.state.bairros;
            if (this.finalidade) {
                bairros = bairros.filter((bairro) =>
                    bairro.finalidades.includes(this.finalidade)
                );
            }
            if (this.cidade_slug) {
                bairros = bairros.filter(
                    (bairro) => bairro.cidade_slug == this.cidade_slug
                );
            } 
            return bairros;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.selecionados = value || [];
            },
        },
    }
};
</script>
<style lang="less" scoped>
    .show {
        display: block!important;
        input[type=checkbox] {
            pointer-events: none;
        }
    }
</style>