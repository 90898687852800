import qs from 'qs';

let query = qs.parse(location.search.replace(/^\?/igm,''));
let {
    modalidade = '',
    codigos = [],
    cidade = '',
    bairros = [],
    tipos = [],
    dormitorios = 0,
    vagas = 0,
    valor_de = 0,
    valor_ate = 0,
    area_de = 0,
    area_ate = 0
} = query;

let finalidade = /\/venda/.test(location.pathname) ? 'venda' : 'aluguel';



export default {
    cidades: [],
    bairros: [],
    tipos: [],
    finalidade,
    bounds: null,
    searching: false,
    search_result: [],
    search_params: {
        finalidade,
        modalidade,
        codigos,
        cidade,
        bairros,
        tipos,
        dormitorios,
        vagas,
        valor_de,
        valor_ate,
        area_de,
        area_ate
    }

}