<template>
    <input type="text" v-model="internal" />
</template>
<script>
export default {
    props: ['value'],
    data() {
        return {
            internal: 0
        }
    },
    computed: {
        valor: {
            get() {
                return this.internal
            },
            set(value) {
                let valor = +(value||'').replace(/\D+/igm,'');
                this.$emit('input', valor);
            }
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                let valor = (value||'').replace(/\D+/igm,'');
                if (value != valor) {
                    this.$emit('input', valor);
                } else {
                    this.internal = +valor;
                }
            }
        }
    }
}
</script>