<template>
    <form action="lista" class="banner-filter" @submit.prevent.stop="filtrar">
        <div class="filter-mode">
            <label :class="{ active: finalidade == 'aluguel' }">
                <input
                    type="radio"
                    v-model="finalidade"
                    value="aluguel"
                    checked="checked"
                />
                quero alugar
            </label>
            <label :class="{ active: finalidade == 'venda' }">
                <input type="radio" v-model="finalidade" value="venda" />
                quero comprar
            </label>
        </div>
        <div class="content">
            <ul class="type-list">
                <li>
                    <label @click.stop.prevent="modalidade = modalidade == 'comercial' ? '' : 'comercial' ">
                        <input
                            type="radio"
                            v-model="modalidade"
                            value="comercial"
                        />
                        comercial
                    </label>
                </li>
                <li>
                    <label @click.stop.prevent="modalidade = modalidade == 'residencial' ? '' : 'residencial' ">
                        <input
                            type="radio"
                            v-model="modalidade"
                            value="residencial"
                        />
                        residencial
                    </label>
                </li>
            </ul>
            <div class="row">
                <div class="sm-12 md-6">
                    <SelectCidade
                        v-model="cidade"
                        :finalidade="finalidade"
                    ></SelectCidade>
                </div>
                <div class="sm-12 md-6">
                    <SelectBairros
                        :finalidade="finalidade"
                        :cidade_slug="cidade"
                        v-model="bairros"
                    ></SelectBairros>
                </div>
            </div>
            <SelectTipos v-model="tipos" :finalidade="finalidade"></SelectTipos>
            <div class="row align-center">
                <div class="sm-12 md-7 xl-6">
                    <button type="submit" class="default-button arrow full">
                        buscar imóvel
                        <img :src="`static/images/arrow.png`" alt="" />
                    </button>
                </div>
                <div class="sm-12 md-5 xl-6">
                    <a href="javascript:void(0);" class="advanced-open"
                        >Busca Avançada</a
                    >
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import SelectCidade from "./select-cidades.vue";
import SelectTipos from "./select-tipos.vue";
import SelectBairros from "./select-bairros.vue";
import qs from "qs";
export default {
    components: {
        SelectCidade,
        SelectTipos,
        SelectBairros,
    },
    data() {
        return {
            modalidade: null,
            cidade: null,
            bairros: [],
            tipos: [],
        };
    },
    computed: {
        finalidade: {
            get() {
                return this.$store.state.finalidade;
            },
            set(value) {
                this.$store.commit("setFinalidade", value);
            },
        },
    },
    methods: {
        filtrar() {
            let query = {
                modalidade: this.modalidade,
                cidade: this.cidade,
                bairros: this.bairros,
                tipos: this.tipos,
            };

            query = qs.stringify(query, { arrayFormat: "brackets" });

            let url = `${document.baseURI}${this.finalidade}?${query}`;
            location.href = url;
        },
    },
    watch: {
        finalidade() {
            this.cidade = null;
            this.bairros = [];
            this.tipos = [];
        },
    },
};
</script>
<style lang="less" scoped>
    input[type=radio] {
        pointer-events: none;
    }
</style>