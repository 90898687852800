<template>
    <div>
        <div class="google-map" ref="googleMap"></div>
        <template v-if="Boolean(this.google) && Boolean(this.map)">
            <slot :google="google" :map="map" />
        </template>
    </div>
</template>
<script>
import GoogleMapsApiLoader from "google-maps-api-loader";

export default {
    props: {
        mapConfig: Object,
        apiKey: String
    },

    data() {
        return {
            google: null,
            map: null
        };
    },

    async mounted() {
        const googleMapApi = await GoogleMapsApiLoader({
            apiKey: this.apiKey
        });
        this.google = googleMapApi;
        this.initializeMap();
    },

    methods: {
        initializeMap() {
            const mapContainer = this.$refs.googleMap;
            this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
            this.map.addListener("bounds_changed", () =>
                this.$emit("bounds_changed", {
                    map: this.map,
                    google: this.google
                })
            );
        }
    }
};
</script>
<style lang="less">
.wrap-map,
.google-map,
.vue-mapa {
    width: 100%;
    height: 100%;
    @media (max-width: 40em) {
        min-height: 50vh;
    }
}
</style>
<style scoped lang="less">
div {
    height: 100%;
}
</style>