import api from '../plugins/api';

export default {
    async loadCidades({ commit }) {
        try {
            let { data: cidades } = await api.get('cidades');
            commit('setCidades', cidades);
        } catch (err) {
            console.error(err);
        }
    },
    async loadBairros({ commit }) {
        try {
            let { data: bairros } = await api.get('bairros');
            commit('setBairros', bairros);
        } catch (err) {
            console.error(err);
        }
    },
    async loadTipos({ commit }) {
        try {
            let { data: tipos } = await api.get('tipos');
            commit('setTipos', tipos);
        } catch (err) {
            console.error(err);
        }
    },
    async search({ commit, state: { search_params: params, finalidade } }) {
        try {
            commit('setSearchResult', []);
            commit('setSearching', true);
            let search_params = {
                ...params,
                finalidades: [finalidade],
                modalidades: [params.modalidade],
                cidades: [params.cidade],
                dormitorios: [params.dormitorios],
                vagas: [params.vagas],
                select: ['codigo', 'latitude', 'longitude']
            }
            if (params.dormitorios == 0) {
                delete search_params['dormitorios'];
            }
            if (params.vagas == 0) {
                delete search_params['vagas'];
            }
            if (params.modalidade == '') {
                delete search_params['modalidades'];
            }
            ['valor_de','valor_ate'].forEach(key => {
                delete search_params[key];
                if (params[key] != 0) {
                    let key2 = key.replace('valor_', `valor_${finalidade}_`);
                    search_params[key2] = params[key];
                }
            });
            ['area_de','area_ate'].forEach(key => {
                if (params[key] == 0) {
                    delete search_params[key];
                }
            });
            
            if (params.cidade == '') {
                delete search_params['cidades'];
            }
            delete search_params['cidade'];
            delete search_params['finalidade'];
            delete search_params['modalidade'];

            let { data: imoveis } = await api.get('imoveis/search', {
                params: search_params
            });
            imoveis = imoveis.map(({ codigo, latitude: lat, longitude: lng }) => ({ codigo, lat, lng }))
            commit('setSearchResult', imoveis);
        } catch (err) {
            console.error(err);
        }
        commit('setSearching', false);
    }
}